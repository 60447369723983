<template>
  <tr>
    <td
      class="px-4"
      style="width: 20%"
    >
      <span style="font-size: 11px">{{ productItem.barCode }}</span>
      <br />
      <span style="font-size: 12px">({{ productItem.productCode }})</span>
      <br />
      <span>{{ productItem.name }}</span>
    </td>
    <td
      class="px-4 text-right"
      style="width: 5%"
    >
      <span v-if="stockSlipType !== STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER">
        {{ productItem.totalQuantityInStock }}
      </span>
      <span v-else>{{ productItem.warrantyQuantity }}</span>
    </td>
    <td
      class="px-4"
      style="width: 19%"
    >
      <div v-if="editState">
        <b-form-textarea
          :disabled="productItem.productType !== productType.PRODUCT_IMEI"
          style="border-color: #007bff"
          size="sm"
          v-model="productItem.IMEI"
          :placeholder="'IMEI'"
          @keyup="enterClicked()"
          @change="checkValidation"
          class="w-100 h-90"
        ></b-form-textarea>
      </div>
      <span v-else>{{ productItem.IMEI }}</span>
    </td>
    <td
      class="px-4 text-right"
      style="width: 14%;"
    >
      <div v-if="editState">
        <b-form-input
          :disabled="productItem.productType === productType.PRODUCT_IMEI"
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
          "
          v-model="productItem.quantity"
          type="number"
          :min="0"
          placeholder="Số lượng"
          class="w-100 h-90 text-right"
          @change="checkValidation"
        ></b-form-input>
        <b-form-invalid-feedback
          id="input-live-feedback"
          :state="$v.productItem.quantity.minValue"
        >
          Nhập số lượng lớn hơn 0
        </b-form-invalid-feedback>
      </div>
      <span v-else>{{ productItem.quantity }}</span>
    </td>
    <td
      class="px-4 text-right"
      style="width: 20%;"
    >
      <div v-if="editState">
        <b-form-input
          size="sm"
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
          "
          v-model="productItem.price"
          placeholder="Đơn giá"
          :min="1"
          class="w-100 h-90 text-right"
          @change="checkValidation"
          v-mask="mask"
        ></b-form-input>
        <p
          style="color: red"
          v-if="error"
        >Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ formatMoney(productItem.price) }}</span>
    </td>

    <td
      class="px-4 text-right"
      style="width: 15%;"
    >
      <span>{{ formatMoney(totalPriceOfProduct) }}</span>
    </td>

    <td
      style="width: 10%"
      v-if="editState"
    >
      <template>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="text-danger"
            @click="showDeleteAlert"
            v-b-tooltip
            title="Xóa"
          >mdi-delete</v-icon>
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import Swal from 'sweetalert2';
import { currencyMask, unMaskPrice } from '@/utils/common';
import { PRODUCT_TYPE } from '@/utils/enum';
import { validationMixin } from 'vuelidate';
import { minValue } from 'vuelidate/lib/validators';
import { STOCK_SLIP_TYPE } from '@/utils/enum';

export default {
  mixins: [validationMixin],
  props: {
    productItem: { type: Object },
    index: { type: Number },
    status: { type: Number },
    editState: { type: Boolean },
    stockSlipType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      STOCK_SLIP_TYPE,
      edit: true,
      error: false,
      mask: currencyMask,
      productType: PRODUCT_TYPE,
    };
  },
  validations: {
    productItem: {
      quantity: {
        minValue: minValue(1),
      },
    },
  },
  computed: {
    totalPriceOfProduct() {
      if (this.productItem.name != '') {
        return this.productItem.quantity * unMaskPrice(this.productItem.price);
      } else {
        return this.productItem.totalPrice;
      }
    },
  },
  created() {},
  updated() {
    this.checkAddRow();
  },
  methods: {
    checkValidation() {
      this.error = false;
      this.edit = true;
      let dataResponse = this.productItem;
      dataResponse.price = unMaskPrice(this.productItem.price);
      this.$emit('update', dataResponse);
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi phiếu nhập không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.productItem.id);
        }
      });
    },
    checkAddRow() {
      this.edit = true;
    },
    enterClicked() {
      let countLine = 0;
      const tmp = this.productItem.IMEI.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.productItem.quantity = countLine;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>
